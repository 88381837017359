.button {
   font-size: 0.938rem;
   line-height: 1em;
   font-weight: var(--regular-font);
   text-align: center;
   color: var(--grey-color);
   padding: 11px;
   border: 1px solid var(--grey-color);
   border-radius: 20px;
   cursor: pointer;
   transition: all 0.3s;
}

.button.active,
.button:hover {
   color: var(--white-color);
   border: 1px solid var(--white-color);
}

.button.small {
   padding: 9px 11px;
}

.button.hover-active {
   color: var(--white-color);
   border: 1px solid var(--white-color);
}
