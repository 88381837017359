.preview {
   position: relative;
   flex: 1;
   max-width: 100%;
   max-height: 100%;
   padding-top: 60px;
   padding-bottom: 12px;
   padding-right: 70px;
   background-color: var(--preview-background-color);
   border: 1px solid var(--preview-stroke-color);
   border-radius: 15px;
   z-index: 0;
}

.preview__side {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
   max-width: 460px;
   padding: 20px;
   padding-right: 70px;
}

@media (max-width: 991px) {
   .preview {
      padding: 0;
   }

   .preview__side {
      padding: 12px;
   }
}
