.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
}

.header__title {
   font-size: 1.3rem;
   line-height: 1em;
   font-weight: var(--light-font);
}

.header__title .red {
   font-weight: var(--bold-font);
}

.header__author {
   font-size: 0.75rem;
   line-height: 1em;
   font-weight: var(--light-font);
}

@media (max-width: 991px) {
   .header {
      margin-bottom: 12px;
   }

   .header__author {
      font-size: 0.625rem;
   }
}
