*,
*::before,
*::after {
   box-sizing: border-box;
}

* {
   padding: 0;
   margin: 0;
}

body {
   font-family: 'Lato', sans-serif;
   line-height: 1.5em;
   font-size: 16px;
   background-color: var(--background-color);
   color: var(--white-color);
   font-weight: var(--regular-font);
}

input,
textarea {
   font-family: 'Lato', sans-serif;
}

img,
picture,
video,
canvas,
svg {
   display: block;
   max-width: 100%;
}

.red {
    color: var(--red-color);
}
