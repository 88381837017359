.field {
    margin-top: 30px;
}

.field__label {
    display: block;
    font-size: 0.875rem;
    font-weight: var(--regular-font);
    margin-bottom: 7px;
}

@media (max-width: 991px) {
    .field:nth-child(2) {
        margin-top: 0;
    }

    .field__mobile--hide {
        display: none;
    }
}