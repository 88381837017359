.mainContainer {
   width: 100%;
   height: 100dvh;
   max-width: calc(1290px + 50px);
   padding: 20px 25px;
   margin-left: auto;
   margin-right: auto;
}

.mainContainer__creator {
   position: relative;
   display: flex;
   align-items: stretch;
   height: calc(100dvh - 80px);
   overflow: hidden;
}

@media (max-width: 991px) {
   .mainContainer {
      padding: 12px;
      max-width: 100%;
   }

   .mainContainer__creator {
      height: calc(100dvh - 108px);
   }
}
