:root {
    --white-color: #fff;
    --black-color: #333;
    --grey-color: #aaa;
    --background-color: #171717;
    --preview-background-color: #18181A;
    --preview-stroke-color: #444444;
    --red-color: #a73025;

    /* SELECT */
    --select-hover-color: #eee;
    --select-stroke-color: #ddd;
}