.select {
   position: relative;
   border-radius: 10px;
}

.select__items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    border-bottom: 1px solid var(--select-stroke-color);
}

.select__items.open {
    display: block;
    z-index: 99999;
}